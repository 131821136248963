.global-header {
  padding: 26px 36px;
  height: 90px;
  a, span {
    font-size: 12px;
    line-height: 0.96;
    color: rgba(255, 255, 255, 0.8);
  }

  a:hover, span:hover {
    color: #ffffff;
    opacity: 0.7;
    text-decoration: none;
  }

  span {
    position: relative;
  }

  .profile-icon {
    width: 36px;
    height: 36px;
    line-height: 36px;
    box-shadow: -0.1px 2px 9px 0 rgba(96, 96, 96, 0.1);
  }

  .profile-option {
    position: absolute;
    bottom: 0;
    left: 28px;
    width: 15px;
    height: 15px;
    line-height: 10px;
    box-shadow: -2px 0.1px 9px 0 rgba(63, 30, 84, 0.52);

    i {
      font-size: 10px;
    }
  }

  .profile-name {
    font-size: 16px;
    line-height: 1.21;
    border-bottom: 1px dashed rgba(255, 255, 255, .5);
    padding-bottom: 2px;
    margin-right: 20px;
    position: relative;

    i {
      font-size: 12px;
      position: absolute;
      top: 3px;
    }
  }

  .menu {

    font-family: OpenSansLight;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 300;
    opacity: 0.8;

    &:hover {
      opacity: 0.7;
    }

    i {
      font-size: 15px;
    }
  }

  sup {
    width: 20px;
    height: 20px;
    background-color: #c51b7a;
    box-shadow: -0.1px 2px 9px 0 rgba(96, 96, 96, 0.1);
    font-size: 11px;
    font-weight: 600;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.8);
  }

  .b2b-icon {
    display: inline-block;
    font-size: inherit;
    color: #ffffff;
  }

  .right {
    line-height: 37px;
  }
}

@media screen and (max-width: 992px) {
  .global-header {
    padding: 22px 75px 15px 30px;
  }
}

.divider:after {
  position: absolute;
  top: 0;
  content: '';
  right: 0px;
  height: 15px;
  width: 1px;
  background: #ffffff;
  opacity: 0.4;
}

.divider-sm:after {
  height: 15px;
}

.divider-lg:after {
  height: 20px;
}
