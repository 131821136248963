.btn {
  border-radius: 7px;
  background-color: @magenta;
  box-shadow: 0px 4px 26px 0 rgba(52, 87, 121, 0.09);
  font-size: 16px;
  line-height: 1.25;
  color: @white;
  padding: 14px 30px;

  &.btn-pink:not(:disabled):hover {
    cursor: pointer;
    box-shadow: 0px 4px 15px 0 rgba(197, 27, 122, 0.46);
  }
  &.disabled, &:disabled {
    border-color:  #f9f7f7;
    background-color: #f9f7f7;
    color: #dfdbe1;
    cursor: not-allowed;
    opacity: 1;
    box-shadow: none;
  }
}

button, .btn {
  font-family: OpenSans;
  &:hover, &:focus {
    outline: none;
  }
}
.btn-outline-pink {
  color: @magenta;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px @magenta;
  padding: 14px 30px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 15px 0 rgba(197, 27, 122, 0.46);
    border-color: transparent;
    text-decoration:none;
    color: @magenta;
  }

  &:disabled {
    border: 1px solid #dfdbe1;
    cursor: not-allowed;
  }
}
.btn-purple {
  background-color: @kingfisher-daisy;
  box-shadow: 0px 4px 26px 0 rgba(52, 87, 121, 0.09);

  &:not(:disabled):hover {
    box-shadow: 0px 4px 15px 0 rgba(78, 34, 109, 0.46);
  }
}

.btn-white {
  color: @kingfisher-daisy;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px #4e226d;
  padding: 14px 30px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 15px 0 rgba(78, 34, 109, 0.18);
    border-color: transparent;
    text-decoration:none;
    color: @kingfisher-daisy;
  }

  &:disabled {
    border: 1px solid #dfdbe1;
    cursor: not-allowed;
  }

  &.btn-rounded {
    padding: 9px 20px 10px;
    border-radius: 18px;
    border: solid 1px #4e226d;
    font-size: 14px;
    line-height: 1;
    transition: all 50ms ease;

    &:hover {
      box-shadow: 0px 2px 6.5px 0 rgba(78, 34, 109, 0.18);
      border-color: transparent;
      text-decoration:none;
    }

    &:disabled {
      border: 1px solid #dfdbe1;

      &:hover {
        color: #868e96;
      }
    }
  }

  &.disabled {
    border: solid 1px #dfdbe1;
    color: #dfdbe1;

    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}

.btn-xs {
  padding: 11px 22px 11px;
  border-radius: 18px;
  border: solid 1px #4e226d;
  line-height: 0.8;
}

.btn-small {
  font-size: 14px;
  line-height: 1.25;
  padding: 12px 20px 11px;
}

.close {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 37px;
  overflow: hidden;
  opacity: 1;

  &:hover {
    cursor: pointer;
  }

  &:hover::before, &:hover::after {
    background: @magenta;
    
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #000;
  }

  &::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.thick::before, &.thick::after {
    height: 4px;
    margin-top: -2px;
  }
}

.btn-link {
  border: none;
  background: transparent;
  cursor: pointer;
  color: #0083be;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
}

button:disabled {
  position: relative;
  text-decoration: none;
  i {
    position: absolute;
    left: 0;
    right: 0;
    color: #868e96;
    font-size: x-large;
  }
  &:hover {
    color: #868e96;
  }
}

.number-btns .icon-refresh {
  font-size: 30px;
  color: @kingfisher-daisy;
  cursor: pointer;
}
