.bg-purple {
  background-color: @blue-diamond;
}

.bg-brand-purple {
  background-color: @kingfisher-daisy;
}

.bg-light-gray {
  background-color: @light-gray;
}

.bg-white {
  background: @white;
}

.bg-midnight-gray {
  background: @midnight-express;
}

bg-magnolia {
  background: @magnolia;
}

.bg-fresh-turquoise {
  background: @fresh-turquoise;
}

.text-white {
  color: @white;
}

.text-blue {
  color: @bondi-blue;
}

.text-pink {
  color: @magenta;
}

.text-purple {
  color: @blue-diamond;
}

.text-sapphire-gray {
  color: @sapphire-gray;
}

.text-midnight-gray {
  color: @midnight-express;
}

.text-fresh-turquoise {
  color: @fresh-turquoise;
}