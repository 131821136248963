@import "../css/bootstrap.min.css";
@import "../css/font-awesome.min.css";
.global-header {
  padding: 26px 36px;
  height: 90px;
}
.global-header a,
.global-header span {
  font-size: 12px;
  line-height: 0.96;
  color: rgba(255, 255, 255, 0.8);
}
.global-header a:hover,
.global-header span:hover {
  color: #ffffff;
  opacity: 0.7;
  text-decoration: none;
}
.global-header span {
  position: relative;
}
.global-header .profile-icon {
  width: 36px;
  height: 36px;
  line-height: 36px;
  box-shadow: -0.1px 2px 9px 0 rgba(96, 96, 96, 0.1);
}
.global-header .profile-option {
  position: absolute;
  bottom: 0;
  left: 28px;
  width: 15px;
  height: 15px;
  line-height: 10px;
  box-shadow: -2px 0.1px 9px 0 rgba(63, 30, 84, 0.52);
}
.global-header .profile-option i {
  font-size: 10px;
}
.global-header .profile-name {
  font-size: 16px;
  line-height: 1.21;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  padding-bottom: 2px;
  margin-right: 20px;
  position: relative;
}
.global-header .profile-name i {
  font-size: 12px;
  position: absolute;
  top: 3px;
}
.global-header .menu {
  font-family: OpenSansLight;
  font-size: 24px;
  line-height: 1.33;
  font-weight: 300;
  opacity: 0.8;
}
.global-header .menu:hover {
  opacity: 0.7;
}
.global-header .menu i {
  font-size: 15px;
}
.global-header sup {
  width: 20px;
  height: 20px;
  background-color: #c51b7a;
  box-shadow: -0.1px 2px 9px 0 rgba(96, 96, 96, 0.1);
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
}
.global-header .b2b-icon {
  display: inline-block;
  font-size: inherit;
  color: #ffffff;
}
.global-header .right {
  line-height: 37px;
}
@media screen and (max-width: 992px) {
  .global-header {
    padding: 22px 75px 15px 30px;
  }
}
.divider:after {
  position: absolute;
  top: 0;
  content: '';
  right: 0px;
  height: 15px;
  width: 1px;
  background: #ffffff;
  opacity: 0.4;
}
.divider-sm:after {
  height: 15px;
}
.divider-lg:after {
  height: 20px;
}
footer.main-footer {
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0px 30px 30px;
}
footer.main-footer ul {
  margin: auto 0;
  padding: 0;
}
footer.main-footer ul li {
  padding: 0;
  margin: 0;
  line-height: 12px;
}
footer.main-footer ul li a {
  font-weight: bold;
  font-size: 12px;
  color: #1e2432;
}
footer.main-footer ul li a:hover {
  text-decoration: none;
  color: #a8a9ac;
  opacity: 0.9;
}
footer.main-footer .copyright {
  font-size: 10px;
  color: #a8a9ac;
  width: 100%;
  text-align: left;
  line-height: 13px;
  max-width: 200px;
}
footer.main-footer .divider {
  height: 20px;
  width: 2px;
  background: #a8a9ac;
  opacity: 0.4;
  margin: auto 0;
}
@media screen and (min-width: 768px) {
  footer.main-footer .copyright {
    max-width: 300px;
  }
}
@media screen and (min-width: 992px) {
  footer.main-footer {
    padding: 0px 50px 30px;
  }
  footer.main-footer a {
    line-height: 12px;
  }
  footer.main-footer .copyright {
    margin: auto 12px;
    max-width: 250px;
  }
}
@media screen and (min-width: 1200px) {
  footer.main-footer {
    padding: 10px 100px;
  }
  footer.main-footer .copyright {
    max-width: 330px;
  }
}
.bg-purple {
  background-color: #4f226d;
}
.bg-brand-purple {
  background-color: #643184;
}
.bg-light-gray {
  background-color: #f9f6fb;
}
.bg-white {
  background: #ffffff;
}
.bg-midnight-gray {
  background: #1e2432;
}
bg-magnolia {
  background: #f8f6f9;
}
.bg-fresh-turquoise {
  background: #00b48c;
}
.text-white {
  color: #ffffff;
}
.text-blue {
  color: #0083be;
}
.text-pink {
  color: #c51b7a;
}
.text-purple {
  color: #4f226d;
}
.text-sapphire-gray {
  color: #9699a0;
}
.text-midnight-gray {
  color: #1e2432;
}
.text-fresh-turquoise {
  color: #00b48c;
}
.t-regular {
  font-family: OpenSans;
}
.t-light {
  font-family: OpenSansLight;
}
.t-semibold {
  font-family: OpenSansSemibold;
}
.t-bold {
  font-weight: 600 !important;
}
.t-bolder {
  font-weight: 900 !important;
}
.t1 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.33;
}
.t2 {
  font-size: 16px;
  line-height: 1.5;
}
.t3 {
  font-size: 14px !important;
  font-weight: 300;
  line-height: 1.57;
}
.t4 {
  font-size: 12px;
  line-height: 2;
}
.t-modal {
  font-size: 27px;
  font-weight: 300;
  line-height: 1.33;
}
.text-underline {
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  text-rendering: optimizeLegibility;
  font-family: OpenSansLight;
  color: #1e2432;
}
h1 small,
h2 small,
h3 small,
h4 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small {
  font-size: 65%;
}
h1,
.h1 {
  font-size: 48px;
  line-height: 56px;
}
h2,
.h2 {
  font-size: 32px;
  line-height: 40px;
}
h3,
.h3 {
  font-size: 24px;
  line-height: 32px;
}
h4,
.h4 {
  font-size: 20px;
  line-height: 28px;
}
p.base {
  font-size: 20px;
}
.additional {
  font-size: 14px;
  line-height: 22px;
}
p.additional {
  font-size: 15px;
}
.fn {
  font-size: 12px;
  line-height: 20px;
  color: #a8a9ac;
}
p.fn {
  font-size: 10px;
}
.text-reports-light {
  color: #797979;
}
.text-subtitle {
  font-family: OpenSans;
  font-size: 16px;
  line-height: 24px;
}
.block-subtitle {
  font-size: 12px;
  line-height: 1;
  color: #c51b7a;
  text-transform: uppercase;
}
.error {
  font-size: 12px;
  line-height: 20px;
  color: #c51b57;
}
a {
  color: #0083be;
  font-size: 14px;
  line-height: 22px;
  font-family: OpenSans;
}
a:hover,
a:focus {
  outline: none;
}
.b2b-icon {
  font-family: b2bIcons;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
}
.b2b-icon.icon-timer:before {
  content: "\e916";
  opacity: 0.8;
}
.b2b-icon.icon-notification:before {
  content: "\e90f";
  opacity: 0.8;
}
.b2b-icon.icon-settings:before {
  content: "\e912";
  opacity: 0.8;
}
.b2b-icon.icon-post:before {
  content: "\e901";
}
.b2b-icon.icon-phone-in-talk:before {
  content: "\e903";
}
.b2b-icon.icon-star:before {
  content: "\e904";
}
.b2b-icon.icon-wifi:before {
  content: "\e90c";
}
.b2b-icon.icon-refresh:before {
  content: "\e913";
}
.icon-info {
  font-family: b2bInfo;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
}
.icon-info.icon-info:before {
  content: "\ea0c";
  font-size: 18px;
  color: #0083be;
}
.btn {
  border-radius: 7px;
  background-color: #c51b7a;
  box-shadow: 0px 4px 26px 0 rgba(52, 87, 121, 0.09);
  font-size: 16px;
  line-height: 1.25;
  color: #ffffff;
  padding: 14px 30px;
}
.btn.btn-pink:not(:disabled):hover {
  cursor: pointer;
  box-shadow: 0px 4px 15px 0 rgba(197, 27, 122, 0.46);
}
.btn.disabled,
.btn:disabled {
  border-color: #f9f7f7;
  background-color: #f9f7f7;
  color: #dfdbe1;
  cursor: not-allowed;
  opacity: 1;
  box-shadow: none;
}
button,
.btn {
  font-family: OpenSans;
}
button:hover,
.btn:hover,
button:focus,
.btn:focus {
  outline: none;
}
.btn-outline-pink {
  color: #c51b7a;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px #c51b7a;
  padding: 14px 30px;
}
.btn-outline-pink:hover {
  cursor: pointer;
  box-shadow: 0px 4px 15px 0 rgba(197, 27, 122, 0.46);
  border-color: transparent;
  text-decoration: none;
  color: #c51b7a;
}
.btn-outline-pink:disabled {
  border: 1px solid #dfdbe1;
  cursor: not-allowed;
}
.btn-purple {
  background-color: #643184;
  box-shadow: 0px 4px 26px 0 rgba(52, 87, 121, 0.09);
}
.btn-purple:not(:disabled):hover {
  box-shadow: 0px 4px 15px 0 rgba(78, 34, 109, 0.46);
}
.btn-white {
  color: #643184;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px #4e226d;
  padding: 14px 30px;
}
.btn-white:hover {
  cursor: pointer;
  box-shadow: 0px 4px 15px 0 rgba(78, 34, 109, 0.18);
  border-color: transparent;
  text-decoration: none;
  color: #643184;
}
.btn-white:disabled {
  border: 1px solid #dfdbe1;
  cursor: not-allowed;
}
.btn-white.btn-rounded {
  padding: 9px 20px 10px;
  border-radius: 18px;
  border: solid 1px #4e226d;
  font-size: 14px;
  line-height: 1;
  transition: all 50ms ease;
}
.btn-white.btn-rounded:hover {
  box-shadow: 0px 2px 6.5px 0 rgba(78, 34, 109, 0.18);
  border-color: transparent;
  text-decoration: none;
}
.btn-white.btn-rounded:disabled {
  border: 1px solid #dfdbe1;
}
.btn-white.btn-rounded:disabled:hover {
  color: #868e96;
}
.btn-white.disabled {
  border: solid 1px #dfdbe1;
  color: #dfdbe1;
}
.btn-white.disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
}
.btn-xs {
  padding: 11px 22px 11px;
  border-radius: 18px;
  border: solid 1px #4e226d;
  line-height: 0.8;
}
.btn-small {
  font-size: 14px;
  line-height: 1.25;
  padding: 12px 20px 11px;
}
.close {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 37px;
  overflow: hidden;
  opacity: 1;
}
.close:hover {
  cursor: pointer;
}
.close:hover::before,
.close:hover::after {
  background: #c51b7a;
}
.close::before,
.close::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #000;
}
.close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.close.thick::before,
.close.thick::after {
  height: 4px;
  margin-top: -2px;
}
.btn-link {
  border: none;
  background: transparent;
  cursor: pointer;
  color: #0083be;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0056b3;
}
button:disabled {
  position: relative;
  text-decoration: none;
}
button:disabled i {
  position: absolute;
  left: 0;
  right: 0;
  color: #868e96;
  font-size: x-large;
}
button:disabled:hover {
  color: #868e96;
}
.number-btns .icon-refresh {
  font-size: 30px;
  color: #643184;
  cursor: pointer;
}
#nav:checked + .nav-open i {
  background: #fff;
}
#nav:checked + .nav-open i:nth-child(1) {
  transform: rotate(45deg) translate(5px, 4px);
}
#nav:checked + .nav-open i:nth-child(2) {
  transform: translateX(100px);
}
#nav:checked + .nav-open i:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
}
#nav:checked ~ .nav-container {
  z-index: 9990;
  opacity: 1;
}
#nav:checked ~ .nav-container ul li a {
  opacity: 1;
  transform: translateY(0);
}
@media screen and (max-width: 992px) {
  .nav-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    z-index: -1;
    opacity: 0;
    transition: all 0.2s ease;
  }
  .nav-container ul {
    position: absolute;
    top: 20%;
    transform: translateY(-20%);
    width: 100%;
  }
  .nav-container ul li {
    display: block;
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .nav-container ul li:nth-child(1) a {
    transition-delay: 0.2s;
  }
  .nav-container ul li:nth-child(2) a {
    transition-delay: 0.3s;
  }
  .nav-container ul li:nth-child(3) a {
    transition-delay: 0.4s;
  }
  .nav-container ul li:nth-child(4) a {
    transition-delay: 0.5s;
  }
  .nav-container ul li:nth-child(5) a {
    transition-delay: 0.6s;
  }
  .nav-container ul li:not(:first-child) {
    margin-left: 0;
  }
  .nav-container ul li a {
    padding: 10px 20px;
    opacity: 0;
    color: #fff;
    font-size: 18px;
    transform: translateY(-20px);
    transition: all 0.2s ease;
  }
  .nav-open {
    position: absolute;
    right: 10px;
    top: 15px;
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 9999;
  }
  .nav-open i {
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 2px;
    margin-left: 20px;
  }
  .nav-open i:nth-child(1) {
    margin-top: 20px;
    transition: transform 0.3s ease;
  }
  .nav-open i:nth-child(2) {
    margin-top: 4px;
    transition: transform 0.3s ease;
  }
  .nav-open i:nth-child(3) {
    margin-top: 4px;
    transition: transform 0.3s ease;
  }
}
@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Regular.eot") format('embedded-opentype'), url("../fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: OpenSansLight;
  src: url("../fonts/OpenSans-Light.eot") format('embedded-opentype'), url("../fonts/OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: OpenSansSemibold;
  src: url("../fonts/OpenSans-Semibold.eot") format('embedded-opentype'), url("../fonts/OpenSans-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: FontAwesome;
  src: url("../fonts/fontawesome-webfont.ttf");
}
html {
  height: 100%;
  box-sizing: border-box;
}
body {
  position: relative;
  margin: 0;
  min-height: 100%;
  padding-bottom: 70px;
  font-family: OpenSans;
  font-size: 16px;
  line-height: 24px;
  color: #1e2432;
  background-color: #f8f6f9;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
.state-active {
  color: #00b461;
}
.state-new {
  color: #8A6B9A;
}
.state-blocked {
  color: #c51b63;
}
.state-pending {
  color: #ff6319;
}
nav ul li a:hover {
  color: #ffffff;
  opacity: 0.7;
  text-decoration: none;
}
.profile {
  border-radius: 4px;
  box-shadow: 0px 5px 21.5px 0 rgba(96, 96, 96, 0.1);
}
.profile header {
  padding: 60px 96px;
  background: rgba(232, 227, 235, 0.3);
}
.profile header h3 {
  font-family: OpenSans;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 20px;
}
.profile header p {
  font-family: OpenSans;
  font-size: 16px;
  line-height: 1.5;
  color: #1e2432;
}
.profile span {
  color: rgba(30, 36, 50, 0.9);
}
.profile .title {
  font-family: OpenSans;
  font-size: 12px;
  line-height: 2.33;
}
.profile > section {
  border-radius: 4px;
  box-shadow: 0px 5px 21.5px 0 rgba(96, 96, 96, 0.1);
}
.profile-form {
  padding-top: 50px;
  padding-bottom: 70px;
}
.profile-wrapper {
  padding-top: 32px;
  padding-bottom: 90px;
}
.page-item {
  background-color: #f8f6f9;
}
.page-item .page-link {
  font-size: 14px;
  line-height: 0.82;
  padding: 8px 8.5px 9px;
  border: 1px solid #ffffff;
}
.page-item .page-link:hover {
  opacity: 1;
}
.page-item .page-link.gray {
  background-color: #f8f6f9;
}
.page-item:not(.active) .page-link:hover {
  background-color: #ffffff;
  border-color: #d8d6e2;
}
.page-item.disabled .page-link {
  font-size: 12px;
  line-height: 0.96;
  color: #c2c5cd;
}
.bottom-wall {
  padding: 66px 130px;
  background-color: #ffffff;
  box-shadow: 0px -4.5px 25px 0 rgba(100, 49, 132, 0.1);
}
.bottom-wall h1 {
  font-size: 48px;
  font-weight: 300;
  line-height: 1.17;
  color: #1e2432;
  margin-bottom: 14px;
}
.bottom-wall .msisdn {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 35px;
}
.bottom-wall .close {
  color: #1e2432;
  opacity: 0.9;
}
.bottom-wall .close span {
  font-size: 2.7em;
}
.bottom-wall thead tr {
  background-color: #ffffff;
  box-shadow: 0px 3px 14px 0 rgba(100, 49, 132, 0.1);
}
.bottom-wall table th {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  text-align: left;
  color: #1e2432;
  border-bottom: none;
}
.bottom-wall table tbody {
  background-color: #f9f6fb;
  font-size: 14px;
  line-height: 1.43;
  color: #1e2432;
}
.bottom-wall table td {
  vertical-align: middle;
}
.guest-img img {
  max-width: 100%;
  max-height: 100%;
}
.arrow {
  position: absolute;
  top: 50%;
  margin-left: 5px;
}
.arrow.arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.arrow.arrow-white {
  border-top: 5px solid #ffffff;
}
.position-static {
  position: static;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-fixed {
  position: fixed;
}
.position-sticky {
  position: sticky;
}
.cursor-pointer {
  cursor: pointer;
}
i.arrow-icon {
  border: solid rgba(186, 193, 210, 0.7);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2.5px;
}
i.arrow-icon.dark {
  border-color: #1e2432;
}
i.arrow-icon.thin {
  border-width: 0 1px 1px 0;
}
i.arrow-icon.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: 1px 7px;
}
i.arrow-icon.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
i.arrow-icon.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
i.arrow-icon.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin: -2px 7px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 115px;
  position: relative;
}
.background-masker {
  background: #fff;
  position: absolute;
}
.selectbox {
  position: absolute;
  max-height: 250px;
  overflow-y: auto;
  top: -22px;
  left: 0;
  padding: 22px 50px 21px 23px;
  border-radius: 4px;
  box-shadow: 0px 5px 22px 0 rgba(100, 49, 132, 0.1);
  z-index: 100;
  line-height: 2;
  font-size: 16px;
}
.selectbox.reports {
  padding: 12px;
  font-family: 'OpenSans', Helvetica, Arial, sans-serif;
  font-weight: 100 !important;
}
.selectbox.reports li {
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 65px 10px 15px;
  color: #909197;
}
.selectbox.reports .list-unstyled:not(.title):hover {
  color: #4f226d;
}
.selectbox.reports .list-unstyled.is-active {
  background: rgba(101, 45, 134, 0.1);
  color: #4f226d;
  pointer-events: none;
  cursor: default !important;
  font-weight: 500 !important;
  border-radius: 3px;
}
.selectbox.right {
  top: 12px;
  right: 0;
  left: auto;
}
.selectbox.left {
  top: -20px;
  right: 70px;
  left: auto;
}
.selectbox li {
  white-space: nowrap;
  cursor: pointer;
}
.selectbox li:not(.title):hover {
  color: #c51b7a;
}
.selectbox li.is-active {
  font-weight: 600;
  color: #c51b7a;
  pointer-events: none;
  cursor: default;
}
.selectbox.profile-option-selectbox {
  right: -10px;
  left: auto;
}
.selectbox.profile-option-selectbox .icon {
  flex: 0 0 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 13px;
  text-align: center;
  font-size: 20px;
  line-height: 36px;
}
.selectbox.profile-option-selectbox .title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  white-space: normal;
}
.selectbox .title {
  font-family: OpenSansSemibold;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.29;
  margin-bottom: 8px;
}
.selectbox ul {
  line-height: 2;
  font-size: 16px;
}
.selectbox ul li {
  white-space: nowrap;
}
.selectbox ul li:not(.title):hover {
  color: #c51b7a;
}
.selectbox-title {
  cursor: pointer;
}
.selectbox .close {
  width: 15px;
  height: 15px;
  margin-top: -8px;
  margin-right: -30px;
}
.submenu {
  box-shadow: 0px 4.5px 10.5px 0 rgba(100, 49, 132, 0.09);
}
.submenu .main-tabs {
  padding: 54px 130px 22px;
  font-family: OpenSansLight;
}
.submenu .main-tabs a {
  font-family: OpenSansLight;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: #a8a9ac;
}
.submenu .main-tabs a:hover {
  text-decoration: none;
  color: #1e2432;
}
.submenu .main-tabs a.active {
  font-size: 32px;
  line-height: 1;
  color: #1e2432;
}
.submenu .main-tabs a.inactive {
  color: #d1d3d9;
  cursor: not-allowed;
}
.submenu .search input {
  padding-left: 35px;
  padding-right: 130px;
  margin-right: 15px;
}
.submenu .search i.fa-search {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  left: 15px;
  font-size: 14px;
  opacity: 0.5;
}
.submenu .links a {
  margin-left: 20px;
}
@media screen and (max-width: 992px) {
  .submenu .main-tabs {
    padding: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .submenu .main-tabs a {
    line-height: 32px;
  }
}
.form-control:focus {
  background-color: #f8f6f9;
}
.error-page {
  padding: 68px 0;
}
.error-page .error-circle {
  width: 587px;
  height: 587px;
  background-color: #f2eff4;
  border-radius: 50%;
  margin: 0 auto;
  padding-left: 65px;
  padding-right: 65px;
  text-align: center;
}
.error-page .error-circle h2 {
  margin-bottom: 30px;
}
.error-page .error-circle p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.44;
  color: rgba(79, 34, 109, 0.5);
}
.main {
  min-height: 560px;
  overflow-x: hidden;
  padding-bottom: 30px;
}
.popup-open section:not(.attention):not(.bottom-wall),
.popup-open div:not(.notifications-block):not(.attention-body):not(.form-check):not(.attention-container) {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  pointer-events: none;
}
.fs-15 {
  font-size: 20px;
}
a:hover {
  text-decoration: none;
}
.action-ismet {
  display: inline-block;
  padding: 10px 20px;
}
.action-ismet:hover i {
  color: #c51b7a;
}
.icon-position {
  position: absolute;
  color: #0083be;
  top: 9px;
  right: 10px;
  cursor: pointer;
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  background-color: #ccc;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #6d6d6d;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  left: 21px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.hint-link {
  color: lightseagreen !important;
}
.hint-link:hover {
  color: #ffffff !important;
}
