.t-regular {
  font-family: OpenSans;
}

.t-light {
  font-family: OpenSansLight;
}

.t-semibold {
  font-family: OpenSansSemibold;
}

.t-bold {
  font-weight: 600 !important;
}
.t-bolder {
  font-weight: 900 !important;
}

.t1 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.33;
}

.t2 {
  font-size: 16px;
  line-height: 1.5;
}

.t3 {
  font-size: 14px !important;
  font-weight: 300;
  line-height: 1.57;
}

.t4 {
  font-size: 12px;
  line-height: 2;
}
.t-modal {
  font-size: 27px;
  font-weight: 300;
  line-height: 1.33;
}

.text-underline {
  text-decoration: underline;
}

// Headings
// -------------------------

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  text-rendering: optimizeLegibility;
  font-family: OpenSansLight;
  color: @midnight-express;

  small,
  .small {
    font-size: 65%;
  }
}

h1, .h1 {
  font-size: @font-size-h1;
  line-height: @line-height-h1;
}

h2, .h2 {
  font-size: @font-size-h2;
  line-height: @line-height-h2;
}

h3, .h3 {
  font-size: @font-size-h3;
  line-height: @line-height-h3;
}

h4, .h4 {
  font-size: @font-size-h4;
  line-height: @line-height-h4;
}

p.base {
  font-size: 20px;
}

.additional {
  font-size: 14px;
  line-height: 22px;
}

p.additional {
  font-size: 15px;
}

.fn {
  font-size: 12px;
  line-height: 20px;
  color: @mischka;
}

p.fn {
  font-size: 10px;
}

.text-reports-light {
  color: #797979
}

.text-subtitle {
  font-family: OpenSans;
  font-size: 16px;
  line-height: 24px;

}

.block-subtitle {
  font-size: 12px;
  line-height: 1;
  color: @magenta;
  text-transform: uppercase;
}

.error {
  font-size: 12px;
  line-height: 20px;
  color: #c51b57;
}

a {
  color: #0083be;
  font-size: 14px;
  line-height: 22px;
  font-family: OpenSans;

  &:hover, &:focus {
    outline: none;
  }
}

.b2b-icon {
  font-family: b2bIcons;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-style: normal;

  &.icon-timer:before {
    content: "\e916";
    opacity: 0.8;
  }

 
  &.icon-notification:before {
    content: "\e90f";
    opacity: 0.8;
  }

  &.icon-settings:before {
    content: "\e912";
    opacity: 0.8;
  }

  &.icon-post:before {
    content: "\e901";
  }

  &.icon-phone-in-talk:before {
    content: "\e903";
  }

  &.icon-star:before {
    content: "\e904";
  }

  &.icon-wifi:before {
    content: "\e90c";
  }

  &.icon-refresh:before {
    content: "\e913";
  }
}

.icon-info {
  font-family: b2bInfo;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-style: normal;

  &.icon-info:before {
    content: "\ea0c";
    font-size: 18px;
    color: #0083be;
  }
}

