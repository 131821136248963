footer.main-footer {
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0px 30px 30px;
  ul {
    margin: auto 0;
    padding: 0;
    li {
      padding: 0;
      margin: 0;
      line-height: 12px;
      a {
        font-weight: bold;
        font-size: 12px;
        color: @midnight-express;
        &:hover {
          text-decoration: none;
          color: @mischka;
          opacity: 0.9;
        }
      }
    }
  }

  .copyright {
    font-size: 10px;
    color: @mischka;
    width: 100%;
    text-align: left;
    line-height: 13px;
    max-width: 200px;
  }
  .divider {
    height: 20px;
    width: 2px;
    background: @mischka;
    opacity: 0.4;
    margin: auto 0;
  }
}
@media screen and (min-width: 768px) {
  footer.main-footer {
    .copyright {
      max-width: 300px;
    }
  }
}
@media screen and (min-width: 992px) {
  footer.main-footer {
    // position: absolute;
    padding: 0px 50px 30px;
    a {
      line-height: 12px;
    }
    .copyright {
      margin: auto 12px;
      max-width: 250px;
    }
  }
}
@media screen and (min-width: 1200px) {
  footer.main-footer {
    padding: 10px 100px;
    .copyright {
      max-width: 330px;
    }
  }
}
