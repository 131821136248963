#nav:checked + .nav-open i {
  background: #fff;
}

#nav:checked + .nav-open i:nth-child(1) {
  transform: rotate(45deg) translate(5px, 4px);
}

#nav:checked + .nav-open i:nth-child(2) {
  transform: translateX(100px);
}

#nav:checked + .nav-open i:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
}

#nav:checked ~ .nav-container {
  z-index: 9990;
  opacity: 1;
}

#nav:checked ~ .nav-container ul li a {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 992px) {
  .nav-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    z-index: -1;
    opacity: 0;
    transition: all 0.2s ease;
  }

  .nav-container ul {
    position: absolute;
    top: 20%;
    transform: translateY(-20%);
    width: 100%;
  }

  .nav-container ul li {
    display: block;
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .nav-container ul li:nth-child(1) a {
    transition-delay: 0.2s;
  }

  .nav-container ul li:nth-child(2) a {
    transition-delay: 0.3s;
  }

  .nav-container ul li:nth-child(3) a {
    transition-delay: 0.4s;
  }

  .nav-container ul li:nth-child(4) a {
    transition-delay: 0.5s;
  }

  .nav-container ul li:nth-child(5) a {
    transition-delay: 0.6s;
  }

  .nav-container ul li:not(:first-child) {
    margin-left: 0;
  }

  .nav-container ul li a {
    padding: 10px 20px;
    opacity: 0;
    color: #fff;
    font-size: 18px;
    transform: translateY(-20px);
    transition: all 0.2s ease;
  }

  .nav-open {
    position: absolute;
    right: 10px;
    top: 15px;
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 9999;
  }

  .nav-open i {
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 2px;
    margin-left: 20px;
  }

  .nav-open i:nth-child(1) {
    margin-top: 20px;
    transition: transform 0.3s ease;
  }

  .nav-open i:nth-child(2) {
    margin-top: 4px;
    transition: transform 0.3s ease;
  }

  .nav-open i:nth-child(3) {
    margin-top: 4px;
    transition: transform 0.3s ease;
  }
}